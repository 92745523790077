body {
    --primary: #19b394;
    --background: #f7fafc;
    --background-dim: #f7fafc;
    --background-dim-light: #f7fafc;
    --primary-foreground: #f7fafc;
    --foreground: #161616;
    --base-unit: 8px;

}

.custom-header {
    align-items: center;
    padding: var(--sd-page-vertical-padding) var(--sd-page-vertical-padding);
    overflow: auto;
    font-family: var(--font-family, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif);
    gap: calc(4 * var(--base-unit, 8px));
    box-shadow: 0px 2px 0px var(--primary, #19b394);
}

.custom-header>div>img {
    margin-top: 20px
}

.sd-element__title .sd-element__num {
    color: var(--foreground,#161616);
    padding-top: 4px;
    font-size: calc(2 * var(--base-unit, 8px));
    line-height: calc(2 * var(--base-unit, 8px));
}